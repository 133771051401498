<template>

  <div class="row">

    <template v-if="loading">
      <!-- Skeleton para a imagem de capa -->
      <div class="col skeleton skeleton-cover"></div>
    </template>
    <template v-else>
      <div class="col"
        :style="{ backgroundImage: `url('${personalization.coverImg || defaultCoverImg}')`, backgroundSize: 'cover', height: '250px', backgroundRepeat: 'no-repeat' }">
      </div>
    </template>
  </div>

  <div class="container" id="main">

    <div class="row justify-content-center">
      <div class="col-sm-9 col-md-7 col-lg-5">

        <div class="card shadow p-2 mb-3 bg-white">
          <div class="text-center mb-2">

            <template v-if="loading">
              <!-- Skeleton para a imagem do logo -->
              <div class="skeleton skeleton-logo"></div>
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-subtext"></div>
            </template>
            <template v-else>
              <img class="rounded-circle img-thumbnail img-thumb-business"
                :src="personalization.logoImg || defaultLogoImg" width="95" height="95" alt="">
              <h5>{{ personalization.businessName || 'Nome do negócio' }}</h5>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ personalization.businessAddress ||
                'Endereço do negócio' }}</div>
            </template>
          </div>
          <div class="card-body">

            <template v-if="loadingSteps">
              <!-- Skeleton para o corpo do card -->
              <div class="skeleton skeleton-body"></div>
              <div class="skeleton skeleton-body"></div>
              <div class="skeleton skeleton-body"></div>
            </template>

            <template v-else>

              <div v-if="step === 1">

                <div class="row p-2">
                  <!-- Step 1: Escolher o Profissional -->
                  <h3 class="mb-3">Passo 1: Escolha o Profissional</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="professional in professionals" :key="professional.id">
                      <div class="d-flex align-items-center">
                        <img
                          :src="professional.photo ? professional.photo : 'https://mdbootstrap.com/img/new/avatars/8.jpg'"
                          alt="" style="width: 45px; height: 45px" class="rounded-circle" />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ professional.name }}</p>
                          <p class="text-muted mb-0">Disponível</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill"
                        @click="selectProfessional(professional.id)">Selecionar</button>
                    </li>

                  </ul>
                </div>


                <div class="row p-2">
                  <button class="btn btn-primary rounded-pill  mb-3" width="100%"
                    @click="selectProfessional(0)">QUALQUER
                    PROFISSIONAL</button>
                </div>

              </div>

              <div v-else-if="step === 2">

                <div class="row p-2">

                  <!-- Step 2: Escolher o Serviço -->
                  <h3 class="mb-3">Passo 2: Escolha o Serviço</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="service in services" :key="service.id">
                      <div class="d-flex align-items-center">
                        <img src="@/assets/arrow-icon.png" alt="" style="width: 45px; height: 45px"
                          class="rounded-circle" />
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ service.title }}</p>
                          <p class="text-muted mb-0">R$ {{ service.price }} - {{ service.duration }} minutos</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill"
                        @click="selectService(service.id)">Selecionar</button>
                    </li>

                  </ul>

                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>

              </div>

              <div v-else-if="step === 3">
                <div class="row p-2">
                  <!-- Step 3: Seleção de data -->
                  <h3 class="mb-3">Passo 3: Escolha a data do agendamento</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <li class="list-group-item d-flex justify-content-between align-items-center"
                      v-for="(day, index) in days" :key="index">
                      <div class="d-flex align-items-center">
                        <div class="ms-3">
                          <p class="fw-bold mb-1">{{ day.day }}</p>
                          <p class="text-muted mb-0">{{ day.wekDay }}</p>
                        </div>
                      </div>
                      <button class="btn btn-primary rounded-pill" @click="selectDay(day.day)">Selecionar</button>
                    </li>

                  </ul>

                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>
              </div>

              <div v-else-if="step === 4">
                <div class="row">
                  <!-- Step 4: Seleção de horario -->
                  <h3 class="mb-3">Passo 4: Selecione o horário do seu agendamento</h3>

                  <ul class="list-group list-group-light p-0 mt-4 mb-4">

                    <template v-for="(time, index) in hours" :key="index">
                      <li class="list-group-item d-flex justify-content-between align-items-center"
                        v-if="time.available">
                        <div class="d-flex align-items-center">
                          <div class="ms-3">
                            <p class="fw-bold mb-1">{{ time.time }}</p>
                          </div>
                        </div>
                        <button class="btn btn-primary rounded-pill" @click="selectHour(time.time)">Selecionar</button>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>
              </div>

              <div v-else-if="step === 5">
                <div class="row">
                  <!-- Step 4: Seleção de horario -->
                  <h3 class="mb-3">Passo 5: Preencha seus dados</h3>

                  <div class="mb-3">
                    <div class="form-group">
                      <label for="name">Seu nome</label>
                      <input type="text" class="form-control rounded-pill" id="name" placeholder="Seu nome"
                        model="client.name" />
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="form-group">
                      <label for="phone">Seu telefone</label>
                      <input type="text" class="form-control rounded-pill" id="phone" placeholder="Seu telefone"
                        model="client.phone" />
                    </div>
                  </div>
                </div>

                <div class="row p-2">
                  <button class="btn btn-primary rounded-pill  mb-3" width="100%" @click="createAppointment">REALIZAR
                    AGENDAMENTO</button>
                </div>

                <div class="row p-2">
                  <button class="btn btn-outline-secondary rounded-pill  mb-3" width="100%" @click="(step--)">VOLTAR
                  </button>
                </div>
              </div>

              <div v-else>
                <div class="row p-2">
                  <!-- Passo final: Confirmação -->
                  <h2>Agendamento Confirmado</h2>
                  <p>Profissional: {{ sheduleConfirmed.professional }}</p>
                  <p>Serviço: {{ sheduleConfirmed.service }}</p>
                  <p>Duração: {{ sheduleConfirmed.duration }} minutos</p>
                  <p>Preço: R$ {{ sheduleConfirmed.service }}</p>
                  <p>Dia: {{ selectedDay }}</p>
                  <p>Horário: {{ selectedTime }}</p>
                </div>

                <div class="row p-2">
                  <button class="btn btn btn-outline-secondary rounded-pill  mb-3" width="100%"
                    @click="(step = 1)">REINICIAR
                    AGENDAMENTO
                  </button>
                </div>
              </div>
            </template>
          </div>

        </div>

        <div class="row pt-4 opacity-50 text-center" style="font-size: 14px;">
          <img src="@/assets/kedybee.png" alt="kedybee" class="mx-auto d-block img-fluid w-25">
          <p>https://kedybee.com</p>
          <p>Sistema inteligênte de atendimento ao cliente.</p>
          <p><a href="#">Termos de Uso e Politicas de Privacidade</a></p>
        </div>

      </div>
    </div>


  </div>

</template>

<script>
import axios from 'axios';
export default {
  name: 'MainComponent',
  data() {
    return {
      loading: true,
      loadingSteps: true,
      defaultCoverImg: 'https://universoretro.com.br/wp-content/uploads/2020/10/salao-new-order-hair.jpg',
      defaultLogoImg: '',
      personalization: {
        coverImg: '',
        logoImg: '',
        businessName: '',
        businessAddress: '',
        slug: ''
      },
      step: 1,
      professionals: [],
      selectedProfessional: {},
      selectedProfessionalId: '',
      services: [],
      days: [],
      hours: [],
      selectedService: '',
      selectedDay: '',
      selectedTime: '',
      dateSelected: '',
      client: {
        name: '',
        phone: ''
      },
      sheduleConfirmed: {}
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    selectProfessional(id) {
      this.loadingSteps = true;

      this.selectedProfessionalId = id;
      this.fetchProfessionalData();
      this.nextStep();
    },

    selectService(id) {
      this.loadingSteps = true;

      this.selectedService = id;
      this.fetchProfessionalSheduleDays();
      this.nextStep();
    },

    selectDay(day) {
      this.loadingSteps = true;

      this.selectedDay = day;
      this.fetchProfessionalSheduleHour();
      this.nextStep();
    },

    selectHour(time) {
      this.selectedTime = time;
      this.nextStep();
    },

    convertToISO8601(dateTime) {
      // Dividir a data e a hora
      const [date, time] = dateTime.split(' ');

      // Formatar a data para "YYYY-MM-DD"
      const formattedDate = date.split('/').reverse().join('-');

      // Combinar a data e a hora no formato ISO 8601
      const iso8601DateTime = `${formattedDate}T${time}:00`;

      return iso8601DateTime;
    },

    // Função para converter a data de DD/MM para YYYY-MM-DD
    convertDateToISO(date) {
      const [day, month] = date.split('/');
      const year = new Date().getFullYear(); // Assume o ano atual, ajuste conforme necessário
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    async createAppointment() {
      try {
        this.loadingSteps = true;

        const dateTime = `${this.dateSelected} ${this.selectedTime}`;

        // Converter o dateTime para o formato ISO 8601
        const formattedDateTime = this.convertToISO8601(dateTime);

        // Montar o objeto de dados para enviar na requisição
        const requestData = {
          dateTime: formattedDateTime,
          name: this.client.name,
          phone: this.client.phone,
          serviceId: this.selectedService,
          professionalId: this.selectedProfessional.id,
        };


        // Faz a requisição usando a data convertida
        const response = await axios.post('https://action-page-kedyatende.kedybee.com/shedules/schedule-professional-post', requestData);

        this.sheduleConfirmed = response.data;
        this.nextStep();
        this.loadingSteps = false;
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },

    async fetchProfessionalSheduleHour() {
      try {
        console.log(this.selectedDay);
        // Converte a data do formato DD/MM para YYYY-MM-DD
        const isoDate = this.convertDateToISO(this.selectedDay);
        console.log(isoDate);


        // Faz a requisição usando a data convertida
        const response = await axios.get('https://action-page-kedyatende.kedybee.com/shedules/schedule-professional-hours/' + this.selectedProfessionalId + '?date=' + isoDate);

        this.hours = response.data.timeslots;
        this.dateSelected = response.data.date;
        this.loadingSteps = false;
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },

    async fetchProfessionalSheduleDays() {
      try {

        const response = await axios.get('https://action-page-kedyatende.kedybee.com/shedules/schedule-professional-days/' + this.selectedProfessionalId);


        this.days = response.data;

        this.loadingSteps = false;
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },

    async fetchProfessionalData() {
      try {

        const response = await axios.get('https://action-page-kedyatende.kedybee.com/open-action-page-professional/' + this.selectedProfessionalId);

        this.selectedProfessional = response.data.professional;
        this.services = response.data.services;

        this.loadingSteps = false;
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },

    async fetchData() {
      try {

        const response = await axios.get('https://action-page-kedyatende.kedybee.com/open-action-page/' + this.$route.params.slug);

        this.personalization = response.data.personalization;
        this.professionals = response.data.professionals;
        this.loading = false;
        this.loadingSteps = false;

      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style>
body {
  background-color: #f1f1f1;
}

h3 {
  font-size: 21px;
}

#main {
  margin-top: -100px;
}

.img-thumb-business {
  margin-top: -50px;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-cover {
  height: 250px;
}

.skeleton-logo {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  margin: 0 auto;
}

.skeleton-text {
  width: 60%;
  height: 20px;
  margin: 10px auto;
  border-radius: 4px;
}

.skeleton-subtext {
  width: 80%;
  height: 15px;
  margin: 5px auto;
  border-radius: 4px;
}

.skeleton-body {
  width: 100%;
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
</style>