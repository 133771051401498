import { createApp } from "vue";
import App from "./App.vue";
import { createWebHistory, createRouter } from "vue-router";

import MainComponent from "./components/MainComponent.vue";

// Defina as rotas corretamente
const routes = [
  { path: '/:slug', component: MainComponent }, // Capture slug explicitamente
  { path: '/', redirect: '/default-slug' } // Redirecione para uma rota default se a URL estiver vazia
];

const router = createRouter({
  history: createWebHistory(), // Use createWebHistory() para histórico de navegação HTML5
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
